import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import Title from '../components/title';

import {withStyles} from '@material-ui/core/styles';

import img1 from '../images/busy-angila/img1.jpg'
import img2 from '../images/busy-angila/img2.jpg'
import img3 from '../images/busy-angila/img3.jpg'


const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"}
};

const BusyAnglia = () => (
  <Layout>
    <SEO title="Home"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>BUSY I PRZEWOZY DO ANGLII I POLSKI</Title>
    <div className="container" css={styles.text}>
      <p>Przewóz osób Polska Anglia Polska. Busy Anglia.</p>

      <p>
        <div>W zakresie przewozu osób obsługujemy teren północno–zachodniej Polski.</div>
        <div> Osoby odbieramy z adresu i dowozimy pod wskazany adres.</div>
        <div> Wyjeżdżamy w każdą sobotę.</div>
      </p>

      <p>
        <ul>
          <li css={styles.li}>Wyjazdy z Polski: <strong>SOBOTA</strong></li>
          <li css={styles.li}>Wyjazdy z Angli: <strong>PONIEDZIAŁEK</strong></li>
        </ul>
      </p>

      <p><strong>Dokładną informację o godzinie wyjazdu podajemy telefonicznie.</strong></p>

      <ul>
      <li css={styles.li}>TEL. PL +48 513 900 083</li>
      <li css={styles.li}>TEL. UK +44 759 818 69 16 lub +44 759 904 2617</li>
      <li css={styles.li}>kontak.uktrans@gmail.com</li>
      </ul>

      <h2 className="d-flex justify-content-center"><div>ZGŁOSZENIA PRZYJMOWANE SĄ TELEFONICZNIE!</div></h2>
      <img src={img3} srcSet={`${img3} 300, ${img1} 768w, ${img2} 945w`}/>

      <div css={styles.paragraph}>UK Trans – jesteśmy licencjonowanym przewoźnikiem realizującym przewozy na
        trasie Polska –
        Anglia. Szybko i ekonomicznie dowieziemy Cię na miejsce, przewieziemy paczkę od Ciebie lub dla Ciebie, a nawet i
        zadbamy o przewóz Twojego zwierzaka. Od wielu lat zajmujemy się przewożenie osób i rzeczy na trasie Anglia –
        Polska i na odwrót.
      </div>
      <div css={styles.header}>Busy z Anglii do Polski</div>
      <div css={styles.paragraph}>W związku ze zmianą sytuacji ekonomiczno-społecznej przewozy ludzi i paczek
        pomiędzy Polską i Anglią są
        dzisiaj nierozerwalną częścią naszego życia. Emigranci w Anglii stale przesyłają sobie z bliskimi paczki, nawet
        i takie z żywnością. Angielska dieta wielu Polakom po prostu nie służy. Dzięki możliwości sprawnego przesyłania
        paczek, życie staje się prostsze. A i łatwiej utrzymać bliskie relacje z rodziną. W każdy poniedziałek możesz
        wsiąść do naszego busa w Anglii i dotrzeć do Polski.
      </div>
      <div css={styles.header}>Przewozy osób z Polski do Anglii</div>
      <div css={styles.paragraph}>Dzięki naszemu zespołowi doświadczonych kierowców oraz flocie komfortowych
        samochodów dotrzesz do Anglii bez
        najmniejszych problemów. Wystarczy, że zgłosisz się do nas i wyrazisz chęć przejazdu do Anglii. Zapewniamy
        wygodną i szybką podróż na Wyspy Brytyjskie. Ilość przystanków jest ograniczona do minimum, by skrócić czas
        jazdy, jednak jeśli istnieje potrzeba, aby zatrzymać się w trasie, nie widzimy w tym najmniejszego problemu.
        Przewozy osób z Polski do Anglii opanowaliśmy do perfekcji.
      </div>
      <div css={styles.header}>Busy z Polski do Anglii</div>
      <div css={styles.paragraph}>A jak tylko zatęsknisz za krajem przywieziemy Cię do Polski. Możesz ze sobą
        zabrać swoje rzeczy, prezenty dla
        bliskich, a nawet i swojego pupila. Zapewniamy, że nasi kierowcy są doświadczeni i świetnie sobie radzą na
        długich trasach. Podróż jest zorganizowana w sposób ekonomiczny, przystanki są realizowane w ramach potrzeb,
        jednak nie częściej niż wymaga tego sytuacja. Dbamy o to, abyście w miarę szybko, a przede wszystkim bezpiecznie
        dotarli do domu.
      </div>
      <div css={styles.header}>Przewozy osób z Anglii do Polski</div>
      <div css={styles.paragraph}>W każdą sobotę wyjeżdżamy z Polski, a w każdy poniedziałek z Anglii. Oferujemy
        przejazdy door to door,
        zabierzemy Cię z Twojego domu i dowieziemy na ustalone miejsce. W Polsce obsługujemy teren północno-zachodni.
        Nasze busy zabierają zgłoszone osoby o określonej godzinie. Bardzo dbamy o punktualność, choć czasem może się
        tak zdarzyć, że zatrzyma nas korek z niezależnych od nas przyczyn. W takich sytuacjach informujemy
        zainteresowane osoby o opóźnieniu. Pewnych zdarzeń nie możemy przewidzieć. Wspólna podróż będzie udana, jeśli
        będziemy się dobrze komunikować – my wiemy jak to zrobić, dlatego możemy Wam zagwarantować, że Wasz przejazd z
        Polski do Anglii i na odwrót będzie miłym wspomnieniem.
      </div>

    </div>
  </Layout>
);

export default withStyles(styles)(BusyAnglia);